import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SlugService {

  makeTitle(slug: string) {
    return slug ? slug?.split('-').join(' ') : '$$';
  }

  makeSlug(title: string) {
    return title ? title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') : '$$';
  }
}
